<template>
  <div>
    <section class="section-watch grid-watch" id="services-list">
      <!-- LEFT -->
      <div class="watch-content watch-left">
        <router-link :to="{ name: 'MyWatch', params: { watchId: watchId } }" class="jbtn-watch-link">
          <!-- {{ $C.MODELS_INDEX[watch.model] }} -->
          <img id="watch-model-logo" :src="model.assets.logo" alt="" />
        </router-link>
        <div @click="showMenu = true" id="show-menu" key="show" class="jbtn jbtn-text show-l"
          v-bind:class="{ 'hide-menu': showMenu }">
          <i class="fal fa-chevron-down"></i> Show Menu
        </div>
        <div @click="showMenu = false" id="hide-menu" key="hide" class="jbtn jbtn-text"
          v-bind:class="{ collapse: !showMenu }">
          <i class="fal fa-chevron-up"></i> Hide Menu
        </div>
        <div class="watch-menu" v-bind:class="{ 'hide-l': true, 'show-menu': showMenu }">
          <router-link :to="{ name: 'Certificate', params: { id: watchId } }" class="jbtn jbtn-watch-nav">
            Info
          </router-link>
          <router-link :to="{ name: 'ServicesList', params: { watchId: watchId } }" class="jbtn jbtn-watch-nav active">
            Servicing
          </router-link>
          <router-link :to="{ name: 'ServiceHistory', params: { id: watchId } }" class="jbtn jbtn-watch-nav">Service
            History</router-link>
          <!-- <router-link :to=" { name: 'PaymentsWatch', params: { id: watchId } }" class="jbtn jbtn-watch-nav">Payments</router-link>
        <router-link :to=" { name: 'Tutorials', params: { id: watchId } }" class="jbtn jbtn-watch-nav">Tutorials</router-link> -->
          <router-link :to="{ name: 'Warranty', params: { id: watchId } }"
            class="jbtn jbtn-watch-nav">Warranty</router-link>

          <div v-if="watch.serial" class="mywatch-content jbtn-watch-link watch-right-serial">
            <small>SERIAL</small><br />
            {{ watch.serial }}
          </div>
        </div>
      </div>

      <!-- RIGHT -->
      <div class="watch-content watch-right duke-style">
        <div class="jcard-dark jcard-watch-info">
          <div class="jcard-title">
            <h1>Servicing</h1>
            <p class="p-text p-text-s mt-3">
              A DUKE watch is a mechanical timepiece that inevitably requires care and maintenance such as
              cleaning, watertight seal verification, slight repairs, and oiling, in order to ensure that it
              functions correctly and remains precise. For these reasons, it is recommended to have a revision
              performed at least every 3 years.
            </p>
          </div>

          <!-- catalog grid -->
          <div class="grid-2">
            <div class="text-center">Please select a service:</div>
            <div v-for="(service, index) in servicesList" :key="service.id" @click="openServiceCard(index)"
              class="jcard-duke">
              <i class="fal fa-cog fa-2x mb-3"></i>
              <h5>{{ service.name }}</h5>
            </div>
          </div>
        </div>
      </div>
    </section>

    <!-- <div class="wrapper">
      <section id="services-list" class="section-duke section-dark jpadding jpadding-20 section-unfixed">
        <div class="jtitle jtitle-t40 jtitle-80">
          <p class="p-text p-text-s">
            A DUKE watch is a mechanical timepiece that inevitably requires care and maintenance such as
            cleaning, watertight seal verification, slight repairs, and oiling, in order to ensure that it
            functions correctly and remains precise. For these reasons, it is recommended to have a revision
            performed at least every 3 years.
          </p>
        </div>
        <div class="grid-2">
          <div class="text-center">Please select a service:</div>
          <div
            v-for="(service, index) in servicesList"
            :key="service.id"
            @click="openServiceCard(index)"
            class="jcard-duke"
          >
            <h5>{{ service.name }}</h5>
          </div>
        </div>
      </section>
    </div> -->
  </div>
</template>

<script>
import toast from "@/assets/js/toast";
import C from "@/const";
import firebase from "@/firebase/firebaseInit";
const db = firebase.db;

export default {
  name: "ServicesList",
  data() {
    return {
      showMenu: false,
      servicesList: [],
      isLoading: false,
      watchId: "",
      modelId: "",
      model: {
        id: "",
        name: "First Edition",
        assets: {
          front: "",
          logo: ""
        }
      },
      listenerRegistrations: [],
    };
  },
  methods: {
    //load all services list (load watch first to know which model it is)
    allServices: function () {
      // load watch
      db.collection(C.COLLECTION.WATCHES)
        .doc(this.watchId)
        .get()
        .then(doc => {
          const watch = doc.data();
          watch.id = doc.id;
          // load services for the right model
          const registration = db.collection(C.COLLECTION.SERVICE_CATALOG)
            .where("active", "==", true)
            .where("modelId", "==", watch.modelId)
            .onSnapshot(querySnapshot => {
              this.servicesList.splice(0);
              querySnapshot.forEach(doc => {
                const docdata = doc.data();
                docdata.id = doc.id;
                this.servicesList.push(docdata);
              });
            });
          this.listenerRegistrations.push(registration);
        })
        .catch(error => {
          console.log(error);
          toast.error("Could not load services!");
        });
    },
    // LOAD watch model
    getWatchModel: function () {
      db.collection(C.COLLECTION.WATCH_CATALOG)
        .doc(this.modelId)
        .get()
        .then(doc => {
          const docdata = doc.data();
          docdata.id = doc.id;
          this.model = docdata;
        })
        .catch(error => {
          console.log("Error getting WATCH MODEL document: ", error);
        });
    },
    openServiceCard(index) {
      this.$store.commit("setService", this.servicesList[index]);
      this.$router.push({
        name: "ServiceCard",
        params: { watchId: this.watchId, serviceId: this.servicesList[index].id }
      });
    }
  },
  computed: {
    user: {
      get() {
        return this.$store.getters.getUser;
      }
    },
    watch: {
      get() {
        return this.$store.getters.getWatch;
      }
    }
  },
  mounted() {
    //get watch id from params
    if (this.$route.params.watchId != null && this.$route.params.watchId != "") {
      this.watchId = this.$route.params.watchId;
    }

    //init
    this.allServices();

    //load watch
    var watchRef = db.collection(C.COLLECTION.WATCHES).doc(this.watchId);
    const registration = watchRef.onSnapshot(doc => {
      this.$store.commit("setWatch", doc.data());
      //   this.userId = doc.data().userId;

      //load watch model
      this.modelId = doc.data().modelId;
      this.getWatchModel();
    });
    this.listenerRegistrations.push(registration);
  },
  beforeDestroy() {
    if (this.listenerRegistrations.length > 0) {
      this.listenerRegistrations.forEach(registration => {
        registration();
      });
    }
  }
};
</script>

<style scoped></style>
